import React from 'react';

const Container = () => (
  <div style={{ fontSize: '1.2rem' }}>
    <p>
      Công ty văn phòng phẩm vanphongphamabc.com thành lập năm 2014 với đội ngũ trẻ năng
      động, ABC ra đời với mong muốn xây dựng quy trình cung cấp đồ dùng văn
      phòng phẩm Online nhanh chóng cho các doanh nghiệp lớn, vừa và nhỏ. VPP
      vanphongphamabc.com luôn tự tin về chất lượng sản phẩm, dịch vụ giao hàng cực kỳ nhanh
      và thái độ phục vụ tư vấn tốt nhất. Luôn tự hào là công ty đi đầu tại TP
      HCM trong lĩnh vực văn phòng phẩm online có công nghệ quản lý đơn hàng
      hiện đại, đội ngũ tư vấn tinh gọn giúp giảm chi phí cho Doanh Nghiệp, mang
      lại sự thuận tiện cho Quý Khách Hàng.
    </p>

    <p style={{ textAlign: 'center' }}>
      <img
        src="/assets/gioi-thieu-vpp-fast.jpeg"
        alt="Công ty văn phòng phẩm vanphongphamabc.com"
        className="img-fluid"
        style={{ maxWidth: '100%', margin: 'auto' }}
        width="600px"
      />
    </p>

    <p>
      Văn phòng phẩm ABC là đơn vị chuyên cung cấp văn phòng phẩm như giấy A4,
      giấy in ấn photo Double A, Giấy Excel, Giấy IK PLUS ,Giấy Paper One...,
      bút viết Thiên Long, Bến Nghé, Deli, Artline..., file hồ sơ, kệ, băng keo,
      dịch vụ khắc con dấu lấy liền, dấu tên, dấu vuông, dấu tròn, tampon, mực
      dấu.. cho doanh nghiệp với chiết khấu cao, tư vấn tận tình chu đáo, giao
      hàng văn phòng phẩm TpHCM nhanh nhất. Qúy Công Ty chắc chắn an tâm về chất
      lượng sản phẩm thiết bị văn phòng phẩm và hài lòng về dịch vụ chúng tôi.
    </p>

    <h2>Lý do nhiều doanh nghiệp lớn chọn vanphongphamabc.com</h2>
    <p>
      <strong>Đặt hàng nhanh gọn</strong>
    </p>

    <p>
      Bạn có thể liên hệ trực tiếp qua Hotline: 0964 399 099 để đặt hàng, hoặc
      qua nút chat Messenger Facebook, ngoài ra khi có nhu cầu các loại đồ văn
      phòng phẩm bạn có thể nhập file báo giá để nhận giá nhanh, và chính xác
    </p>

    <p style={{ textAlign: 'center' }}>
      <img
        src="/assets/ly-do-chon-fast.jpeg"
        alt="Tại sao chọn VPP ABC"
        className="img-fluid"
        style={{ maxWidth: '100%', margin: 'auto' }}
        width="600px"
      />
    </p>

    <p>
      <strong>Đặt uy tín lên hàng đầu</strong>
    </p>

    <p>
      Tất cả các mặt hàng văn phòng phẩm mua tại{' '}
      <a href="https://vanphongphamabc.com" target="_blank">
        vanphongphamabc.com
      </a>{' '}
      đều là sản phẩm chính hãng, được kiểm tra kỹ trước khi giao hàng. Khách
      hàng dễ dàng đổi trả nếu không đúng yêu cầu hoặc đổi ý
    </p>
    
    <p>
      Nếu quý khách hàng đang có ý định mở cửa hàng{' '}
      <a
        href="https://vanphongphamabc.com/blog/van-phong-pham/ban-van-phong-pham"
        target="_blank"
      >
        bán văn phòng phẩm
      </a>
      , đừng quên liên hệ ngay với chúng tôi để nhận ưu đãi tốt nhất thị trường.
    </p>

    <h2>Bạn đang cần cửa hàng văn phòng phẩm gần đây nhất?</h2>
    <p>
      Công ty văn phòng phẩm ABC là Top 3 đơn vị cung cấp đồ dùng văn phòng
      phẩm gần nhất tại TpHCM, có trụ sở tại địa chỉ 1174 Võ Văn Kiệt, Phường
      10, Quận 5, TP. Chúng tôi đã nghiên cứu và áp dụng thành công quy trình
      vận hành để giúp việc tối giản quy trình đặt hàng và rút ngắn thời gian xử
      lý đặt hàng.{' '}
    </p>
    <p style={{ textAlign: 'center' }}>
      <img
        src="/assets/cua-hang-vpp-gan-day-nhat.jpeg"
        alt="Cửa hàng văn phòng phẩm gần đây nhất"
        className="img-fluid"
        style={{ maxWidth: '100%', margin: 'auto' }}
        width="600px"
      />
    </p>
    <p>
      Hơn thế nữa việc áp dụng công nghệ 4.0 bằng cách tích hợp công nghệ
      shipper để tối ưu hóa khả năng phân phối từ kho hàng: 671/3 Hồng Bàng,
      Phường 6, Quận 6, TP. HCM để giao hàng nhanh nhất đến quận 2, bình thạnh,
      gò vấp, phú nhuận, quận 1, quận 2, quận 10, quận 3, quận 12, quận 5, quận
      tân bình, tân phú... và các quận huyện khác tại TpHCM nhanh nhất hiện nay.
      Vì vậy khi quý khách hàng có nhu cầu tìm văn phòng gần đây nhất khi cần,
      ABC tự tin đồng hành và phục vụ Quý Khách.
    </p>
  </div>
);

export default Container;
