import React, { useState, memo } from 'react';

import { Carousel } from 'react-bootstrap';
import BannerComponent from '@views/components/banner/Banner';

import {
  BANNER_SIDES,
  BANNER_MAINS,
  BANNER_MOBILE_MAINS,
  BANNER_MOBILE_SIDES,
} from '@mocks/sample-data';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

export const Banner = memo(() => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e) => {
    setSlideIndex(selectedIndex);
  };

  return (
    <BannerComponent as="section" version={1}>
      <div key="first" className="banner__item banner__item--first-slide">
        <Carousel
          activeIndex={slideIndex}
          indicators
          controls
          interval={5000}
          onSelect={handleSelect}
        >
          {BANNER_MAINS.map((item, i) => (
            <Carousel.Item key={`${item.key}-${i}`} title={item.alt}>
              <a
                href={item.url}
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.BANNER}
                data-action={EVENT_ACTION_NAME}
                data-label={EVENT_LABEL.MAIN_HOMEPAGE_BANNER}
              >
                {/* <LazyImage src={item.src} srcSet={item.srcSet} alt={item.alt} /> */}
                <LazyLoadImage
                  image={{
                    alt: item.alt,
                    src: item.src,
                    srcSet: item.srcSet,
                  }}
                />
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {BANNER_SIDES.map(({ key, url, src, srcSet, alt }, index: number) => {
        return (
          <BannerComponent.Item
            as="a"
            aria-label={alt}
            title={alt}
            version={index + 1}
            href={url}
            key={key || `banner-top-${index}`}
            className={cn(EVENT_CLASS_NAME)}
            data-category={EVENT_CATEGORY.BANNER}
            data-action={EVENT_ACTION_NAME}
            data-label={EVENT_LABEL.SUB_HOMEPAGE_BANNER}
          >
            {/* <LazyImage src={src} srcSet={srcSet} alt={alt} /> */}
            {/* <LazyLoadImage
              image={{
                alt,
                src,
                srcSet,
              }}
            /> */}
            <img src={src} srcSet={srcSet} alt={alt} />
          </BannerComponent.Item>
        );
      })}
    </BannerComponent>
  );
});

export const BannerMobile = memo(() => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e) => {
    setSlideIndex(selectedIndex);
  };

  return (
    <BannerComponent as="section" version={1}>
      <div className="banner__item banner__item--first-slide">
        <Carousel
          activeIndex={slideIndex}
          indicators
          controls
          interval={5000}
          onSelect={handleSelect}
        >
          {BANNER_MOBILE_MAINS.map((item, i) => (
            <Carousel.Item key={`${item.key}-${i}`}>
              <a
                href={item.url}
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.BANNER}
                data-action={EVENT_ACTION_NAME}
                data-label={EVENT_LABEL.MAIN_HOMEPAGE_BANNER_MOBILE}
              >
                {/* <LazyImage
                  src={item.src}
                  srcSet={item.srcSet}
                  alt="Văn phòng phẩm ABC"
                /> */}
                <LazyLoadImage
                  image={{
                    alt: 'Văn phòng phẩm ABC',
                    src: item.src,
                    srcSet: item.srcSet,
                  }}
                />
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {BANNER_MOBILE_SIDES.map(({ key, url, src, srcSet }, index: number) => {
        return (
          <BannerComponent.Item
            as="a"
            version={index}
            href={url}
            key={key || `banner-top-${index}`}
            className={cn(EVENT_CLASS_NAME)}
            data-category={EVENT_CATEGORY.BANNER}
            data-action={EVENT_ACTION_NAME}
            data-label={EVENT_LABEL.SUB_HOMEPAGE_BANNER_MOBILE}
          >
            {/* <LazyImage src={src} srcSet={srcSet} alt="Văn phòng phẩm ABC" /> */}
            {/* <LazyLoadImage
              image={{
                alt: 'Văn phòng phẩm ABC',
                src,
                srcSet,
              }}
            /> */}
            <img src={src} srcSet={srcSet} alt="Văn phòng phẩm ABC" />
          </BannerComponent.Item>
        );
      })}
    </BannerComponent>
  );
});

export default Banner;
